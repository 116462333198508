<template>
    <div>
        <div v-for="item in menu" :key="item.menu_id+'div'">
            <el-submenu :index="item.menu_id+''" v-if="item.children&&item.children.length">
                <template slot="title">
                    <span class="icon-font-icon">
                    <i v-if="item.icon&&item.icon.indexOf&&item.icon.indexOf('el-')===0" :class="item.icon"></i>
                    <icon-font v-else  :iconClass="item.icon||''"></icon-font>
                    <span>{{item.title}}</span>
                    </span>
                </template>
                <menu-item :menu="item.children"></menu-item>
            </el-submenu>
            <el-menu-item :index="item.menu_id+''" :route="{path:item.url}" v-else>
                <span class="icon-font-icon">
                <i v-if="item.icon&&item.icon.indexOf&&item.icon.indexOf('el-')===0" :class="item.icon"></i>
                <icon-font v-else  :iconClass="item.icon||''"></icon-font>
                <span slot="title">{{item.title}}</span>
                </span>
            </el-menu-item>
        </div>
    </div>
</template>

<script>
    export default {
        name: "menu-item",
        props:['menu']
    }
</script>
<style scoped>
    .el-menu--collapse .el-submenu .el-submenu__title span{
        height: 0;
        width: 0;
        overflow: hidden;
        visibility: hidden;
        display: inline-block;
    }
    .icon-font-icon{
        display: flex;
        align-items: center;
    }
</style>
