<template>
    <div style="background-color: #fff">
        <div class="pt-3">
            <search @condition="condition" :fields="searchFields">
                <el-button @click="addUser"  v-access="{url:baseUrl,method:'POST'}" size="medium" slot="add" icon="el-icon-plus" type="primary">新建用户组</el-button>
            </search>
        </div>
        <div class="mt-3">
            <my-table  :data="tableData" :fields="showFields">
                <el-table-column slot="operation" fixed="right" width="180" label="操作">
                    <template slot-scope="scope">
                        <el-button
                            v-access="{method:'put',url:baseUrl}"
                            size="mini"
                            @click="editGroup(scope.$index)" icon="el-icon-edit"></el-button>
                        <el-button
                            size="mini"
                            v-if="[1].indexOf(scope.row.group_id)===-1"
                            v-access="{method:'put',url:'/vue/user/systemAuthGroup',except:[1]}"
                            @click="authGroup(scope.row)" icon="el-icon-lock"></el-button>
<!--                        <el-button-->
<!--                            size="mini"-->
<!--                            v-if="[1,2].indexOf(scope.row.group_id)===-1"-->
<!--                            v-access="{method:'delete',url:baseUrl}"-->
<!--                            type="danger"-->
<!--                            @click="deleteGroup(scope.$index,scope.row.group_id)" icon="el-icon-delete"></el-button>-->
                    </template>
                </el-table-column>
            </my-table>
        </div>
        <div class="pb-3 mt-3">
            <el-pagination
                    @size-change="sizeChange"
                    @current-change="pageChange"
                    :current-page="page.pageNumber"
                    :page-sizes="[10, 15, 30, 50]"
                    :page-size="page.pageSize"
                    :background="true"
                    layout="sizes,prev, pager,next,total"
                    :total="page.total">
            </el-pagination>
        </div>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>
<script>
    import F from '@/fields/fields';
    import search from "@/components/common/search";
    import tools from "@/utils/tools";
    export default {
        name: "userGroup",
        components: {
            search
        },
        created() {
            // this.$bbs.get('/manage/user/systemGroup').then(group => {
            //     this.userGroup = group.data;
            // });
        },
        mounted() {

        },
        data: function () {
            return {
                baseUrl:'/bbs/userG/index',
                baseAuthUrl:'/bbs/userG/au',
                page: {
                    search: {},
                    sort: {},
                    total: 1,
                    pageSize: 10,
                    pageNumber: 1,
                },
                userGroup: [],
                tableData: [],
                form: {
                    action_name: '',
                    show: false,
                    title: '',
                    data: {},
                    fields: [],
                    rules: {},
                }
            }
        },
        computed: {
            searchFields: function () {
                return  F.search('systemGroup', 'search');
            },
            showFields: function () {
                return F.show('systemGroup', 'show');
            },
        },
        methods: {
            condition: function (search) {
                this.page.search = search;
                this.page.pageNumber = 1;
                this.search();
            },
            search: function () {
                this.$bbs.get(this.baseUrl, {
                    params: this.page,
                }).then(data => {
                    // console.log(data);
                    this.page.total = data.data.total;
                    this.page.pageNumber = data.data.pageNumber;
                    this.page.pageSize = data.data.pageSize;
                    this.tableData = data.data.data;
                });
            },
            sortChange: function (column) {
                if (!column.order) return;
                this.page.sort = {prop: column.prop, order: column.order};
                this.search();
            },
            sizeChange: function (size) {
                this.page.pageSize = size;
                this.search();
            },
            pageChange: function (page) {
                this.page.pageNumber = page;
                this.search();
            },
            filterPlatform(value, row) {
                return row.tag === value;
            },
            submit: function (data) {
                // console.log(data);
                if (data.type === 'cancel') {
                    this.form.show = false;
                    return;
                }
                switch (this.actionName) {
                    case 'editGroup':
                        this.$bbs.put(this.baseUrl, data.data).then(userData => {
                            this.$message.success('保存成功!');
                            this.tableData.splice(this.index,1,userData.data);
                            this.form.show = false;
                        });
                        break;
                    case 'addGroup':
                        this.$bbs.post(this.baseUrl,data.data).then(userData => {
                            this.$message.success('创建成功!');
                            this.page.total++;
                            this.tableData.unshift(userData.data);
                            this.form.show = false;
                        });
                        break;
                    case 'authGroup':
                        this.$bbs.put(this.baseAuthUrl,data.data).then(() => {
                            this.$message.success('授权成功!');
                            this.form.show = false;
                        });
                        break;
                }
            },
            editGroup: function (index) {
                let form = F.get('systemGroup', 'edit_group', this.tableData[index]);
                this.actionName = 'editGroup';
                this.form = form;
                this.index=index;
            },
            addUser:function(){
                let form = F.get('systemGroup', 'add_group');
                this.actionName = 'addGroup';
                this.form = form;
            },
            authGroup:function(groupData){
                if (this.$store.state.loading) return;
                this.$bbs.get(this.baseAuthUrl,{
                    params:{
                        id:groupData.group_id,
                    }
                }).then(data=>{
                    this.actionName='authGroup';
                    let hasSon={};
                    data.data.all.map(v=>{
                        hasSon[v.pid]=1;
                    });
                    this.form={
                        show:true,
                        title:'授权 '+groupData.group_title+' 用户组',
                        data:{
                            group_id:groupData.group_id,
                            auth:{
                                all:tools.loop(data.data.all,'menu_id'),
                                //此值只能是最底层的菜单
                                value:data.data.auth.filter(v=>!hasSon[v]),
                                // value:[47],
                            },
                        },
                        fields:[
                            {name:'group_id',type:'hidden'},
                            {name:'auth',type:'tree',id:'menu_id',props:{label:'title',children:'children'}}
                        ],
                        rules:{},
                    };
                });
            },
            deleteGroup: function (index,id) {
                this.$confirm('你真的要删除这个用户组吗?','操作确认').then(()=>{
                    this.$bbs.delete(this.baseUrl,{
                        need:{
                            error:true,
                        },
                        data:{id}
                    }).then(()=>{
                        this.tableData.splice(index, 1);
                        this.page.total--;
                        this.$message.success('删除成功!');
                    });
                });
            }
        }
    }
</script>
<style scoped>

</style>
