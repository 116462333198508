export default {
    fields: {
        username: {type: 'primary', title: '用户名'},
        nickname: {title: '昵称', width: 150},
        phone: {title: '电话', width: 150},
        coin: {title: '积分', width: 150},
        login_password: {title: '密码', width: 150, type: 'password',placeholder:'留空表示不修改'},
        re_password: {title: '确认密码', width: 150, type: 'password'},
        group_id: {title: '用户组', type: 'checkbox', dict_key: 'bbs_user_group'},
        tip:{title:'备注',},
        create_time: {title: '创建时间', type: 'date', format: 'Y-m-d'},
    },
    rules: {
        username: [
            {required: true, message: '用户名不可以为空', trigger: 'blur'},
        ],
        staff_name: [
            {required: true, message: '员工名不可以为空', trigger: 'blur'},
        ],
        group_id: [
            {required: true, message: '请指定用户组', trigger: 'change'},
        ],
    },
    group: {
        show: {
            fields: ['username', 'phone', 'nickname', 'group_id','tip', 'create_time'],
        },
        search: {
            fields: ['username', 'phone','group_id'],
        },
        edit_user: {
            fields: ['username', 'group_id', 'login_password','tip'],
            title: '修改用户',
            data: {},
            show: true,
        },
        edit_password: {
            fields: ['login_password', 're_password'],
            title: '修改密码',
            data: {},
            show: true,
        },
        add_user: {
            fields: ['username', 'staff_name', 'group_id', 'password'],
            title: '新增用户',
            data: {},
            show: true,
        },
    }
}
