export  default {
    fields: {
        menu_id:{type:'primary'},
        title: {title: '标题'},
        pid:{type:'hidden'},
        url: {title: '链接'},
        auth_url: {title: '后台地址'},
        icon: {title: '图标'},
        type: {title: '类型', type: 'select', options: [{key: 'menu', label: '菜单'},{key: 'hidden_menu', label: '隐藏菜单'}, {key: 'get', label: 'GET'},{key: 'post', label: 'POST'},{key: 'put', label: 'PUT'},{key: 'delete', label: 'DELETE'}]},
        state: {title: '是否启用', type: 'switch'},
    },
    rules: {
        title: [
            {required: true, message: '标题不可以为空', trigger: 'blur'},
        ]
    },
    group: {
        add_menu: {
            fields: ['title', 'url', 'icon', 'type', 'state','pid'],
            title: '添加菜单',
            data: {type: 'menu', state: true},
            show: true,
        },
        edit_menu: {
            fields: ['menu_id','title', 'url','auth_url', 'icon', 'type', 'state'],
            title: '编辑菜单',
            show: true,
        },
        search: {
            fields: ['title', 'url'],
            title: '编辑菜单',
            show: true,
        },
    }
}