<template>
    <div style="background-color: #fff">
        <div class="pt-3">
            <search @condition="condition" :fields="searchFields">
                <!--                <el-button @click="addUser" v-access="{url:baseUrl,method:'POST'}" size="medium" slot="add"-->
                <!--                           icon="el-icon-plus" type="primary">添加-->
                <!--                </el-button>-->
            </search>
        </div>
        <div class="mt-3">
            <my-table :data="tableData" :fields="showFields">
                <span  slot="invoice" slot-scope="data">
                    <el-link @click="handleInvoice(data)" type="primary">{{ getInvoiceText(data) }}</el-link>
                </span>
                <el-table-column slot="operation" fixed="right" width="100" label="操作">
                    <template slot-scope="scope">
                        <div class="operation-list">
                            <el-link type="primary" @click="seeDetail(scope)">查看</el-link>
                            <el-link type="primary" @click="seeDetail(scope)">编辑</el-link>
                        </div>
                    </template>
                </el-table-column>
            </my-table>
        </div>
        <div class="pb-3 mt-3">
            <el-pagination
                @size-change="sizeChange"
                @current-change="pageChange"
                :current-page="page.pageNumber"
                :page-sizes="[10, 15, 30, 50]"
                :page-size="page.pageSize"
                :background="true"
                layout="sizes,prev, pager,next,total"
                :total="page.total">
            </el-pagination>
        </div>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>
<script>
import F from '@/fields/fields';
import search from "@/components/common/search";
import MyTable from "@/components/common/my-table";

export default {
    name: "appSms",
    components: {
        search, MyTable
    },
    created() {
        this.$store.dispatch('getDict', ['pro_product']);
    },
    mounted() {

    },
    data: function () {
        return {
            baseUrl: '/bbs/answer/my',
            page: {
                search: {},
                sort: {},
                total: 1,
                pageSize: 15,
                pageNumber: 1,
            },
            tableData: [],
            form: {
                action_name: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            }
        }
    },
    computed: {
        searchFields: function () {
            return F.search('answer', 'search');
        },
        showFields: function () {
            return F.show('answer', 'user_show');
        },
    },
    methods: {
        condition: function (search) {
            this.page.search = search;
            this.page.pageNumber = 1;
            this.search();
        },

        search: function () {
            this.$bbs.get(this.baseUrl, {
                params: this.page,
            }).then(data => {
                this.page.total = data.data.total;
                this.page.pageNumber = data.data.pageNumber;
                this.page.pageSize = data.data.pageSize;
                this.tableData = data.data.data;
            });
        },
        sortChange: function (column) {
            if (!column.order) return;
            this.page.sort = {prop: column.prop, order: column.order};
            this.search();
        },
        sizeChange: function (size) {
            this.page.pageSize = size;
            this.search();
        },
        pageChange: function (page) {
            this.page.pageNumber = page;
            this.search();
        },
        filterPlatform(value, row) {
            return row.tag === value;
        },
        handleInvoice(data){
            if (data.all.pay_status!==1) return;
            if (data.data){
                this.$router.push({
                    name:'pro_invoice',
                    params:{
                        id:data.data
                    }
                });
                return;
            }
            //先向服务器请求这个用户有多少的发票抬头
            this.$bbs.get(this.addInvoiceUrl,{
                params:{
                    order_id:data.all.order_id,
                },
                need:{
                    error:true
                }
            }).then(result=>{
                if (!result.data.invoice_header.length){
                    this.$message.warning('请先前往权益中心->发票抬头 添加发票抬头信息');
                    return;
                }
                this.$store.commit('updateDict',{pro_invoice_header:result.data.invoice_header});
                //弹出开票的窗口
                let form = F.get('proInvoiceList', 'add_invoice', {order_id:data.all.order_id,price:data.all.pay_price});
                this.actionName = 'addInvoice';
                this.form = form;
                this.index = data.index;
            });
        },
        getInvoiceText(data) {
            if (data.all.pay_status===1){
                if (data.data){
                    return  '查看';
                }else{
                    return  '申请';
                }
            }else{
                return '';
            }
        },
        submit: function (data) {
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }

            switch (this.actionName) {
                case 'addInvoice':
                    this.$bbs.post(this.addInvoiceUrl, data.data).then(adData => {
                        this.$message.success('申请成功!');
                        this.tableData.splice(this.index, 1, adData.data);
                        this.form.show = false;
                    });
                    break;
                case 'editFree':
                    this.$bbs.put(this.baseUrl, data.data).then(adData => {
                        this.$message.success('保存成功!');
                        this.tableData.splice(this.index, 1, adData.data);
                        this.form.show = false;
                    });
                    break;
                case 'addFree':
                    this.$bbs.post(this.baseUrl, data.data).then(userData => {
                        this.page.total++;
                        this.form.show = false;
                        this.$message.success('添加成功!');
                        this.tableData.unshift(userData.data);
                    });
                    break;
            }
        },
        seeDetail: function (row) {
            let a=document.createElement('a');
            a.target='_blank';
            a.href='/wenda/detail/'+row.row.answer_id+'.html';
            a.click();
            // let form = F.get('freeGis', 'edit', this.tableData[index]);
            // this.actionName = 'editFree';
            // this.form = form;
            // this.index = index;
        },
        addUser: function () {
            let form = F.get('freeGis', 'add');
            this.actionName = 'addFree';
            this.form = form;
        },
        authUser(index, userData) {
            this.$bbs.put(this.baseUrl, {action: 'confirm', free_id: userData.free_id}).then(adData => {
                this.$message.success('赠送成功!');
                this.tableData.splice(this.index, 1, adData.data);
            });
        },
        deleteData: function (index, free_id) {
            this.$confirm('你真的要删除这条信息吗?', '操作确认').then(() => {
                this.$bbs.delete(this.baseUrl, {data: {free_id: free_id}}).then(() => {
                    this.tableData.splice(index, 1);
                    this.page.total--;
                    this.$message.success('删除成功!');
                });
            });
        }
    }
}
</script>

<style lang="less" scoped>
    .operation-list{
        display: flex;
        justify-content: space-around;
    }
</style>
