<template>
    <div style="background-color: #fff">
        <div class="pt-3">
            <search @condition="condition" :fields="searchFields">
                <el-button-group slot="left">
                    <el-button @click="addPiao('pu')" v-access="{url:baseUrl,method:'POST'}" size="medium"
                               icon="el-icon-plus" type="primary">新增普票
                    </el-button>
                    <el-button @click="addPiao('zhuan')" v-access="{url:baseUrl,method:'POST'}" size="medium"
                               icon="el-icon-plus" type="primary">新增专票
                    </el-button>
                </el-button-group>
            </search>
        </div>
        <div id="tableList" class="mt-3">
            <my-table row-key="product_id" :data="tableData" :fields="showFields">
                <el-table-column slot="operation" width="150" label="操作">
                    <template slot-scope="scope">
                        <!--                        <span class="my-sort-dom" :data-id="scope.row.product_id">-->
                        <!--                            <i class="el-icon-rank"></i>-->

                        <!--                        </span>-->
<!--                        <el-button-->
<!--                            size="mini"-->
<!--                            :data-id="scope.row.product_id"-->
<!--                            class="my-sort-dom"-->
<!--                            v-access="{url:baseUrl,method:'PUT'}"-->
<!--                            icon="el-icon-rank">-->
<!--                        </el-button>-->
                        <el-button
                            size="mini"
                            @click="editPiao(scope.$index)" v-access="{url:baseUrl,method:'PUT'}"
                            icon="el-icon-edit"></el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="removePiao(scope.$index)" v-access="{url:baseUrl,method:'DELETE'}"
                            icon="el-icon-remove"></el-button>
                    </template>
                </el-table-column>
            </my-table>
        </div>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>
<script>
import F from '@/fields/fields';
import search from "@/components/common/search";
import MyTable from "@/components/common/my-table";
import Sortable from 'sortablejs';

export default {
    components: {
        search, MyTable
    },
    created() {

    },
    mounted() {

    },
    data: function () {
        return {
            baseUrl: '/bbs/invoice/piaotitle',
            tableData:[],
            page: {
                search: {},
                sort: {},
                total: 1,
                pageSize: 15,
                pageNumber: 1,
            },
            form: {
                action_name: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            }
        }
    },
    computed: {
        searchFields: function () {
            return F.search('invoiceTitle', 'search');
        },
        showFields: function () {
            return F.show('invoiceTitle', 'show');
        },
    },
    methods: {
        condition: function (search) {
            this.page.search = search;
            this.page.pageNumber = 1;
            this.search();
        },
        search: function () {
            this.$bbs.get(this.baseUrl, {
                params: this.page,
            }).then(data => {
                this.tableData = data.data.data;
            });
        },
        sortChange: function (column) {
            if (!column.order) return;
            this.page.sort = {prop: column.prop, order: column.order};
            this.search();
        },
        sizeChange: function (size) {
            this.page.pageSize = size;
            this.search();
        },
        pageChange: function (page) {
            this.page.pageNumber = page;
            this.search();
        },
        filterPlatform(value, row) {
            return row.tag === value;
        },
        submit: function (data) {
            // this.con(data);
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }
            switch (this.actionName) {
                case 'editPiao':
                    this.$bbs.put(this.baseUrl, data.data).then(adData => {
                        this.$message.success('保存成功!');
                        this.tableData.splice(this.index, 1, adData.data);
                        this.form.show = false;
                    });
                    break;
                case 'addPiao':
                    this.$bbs.post(this.baseUrl, data.data).then(userData => {
                        this.page.total++;
                        this.$message.success('添加成功!');
                        this.form.show = false;
                        this.tableData.unshift(userData.data);
                    });
                    break;
            }
        },
        editPiao: function (index) {
            let form;
            let data=this.tableData[index];
            if (data.type==='pu'){
                form = F.get('invoiceTitle', 'edit_pu', data);
            }else{
                form = F.get('invoiceTitle', 'edit_zhuan', data);
            }
            this.actionName = 'editPiao';
            this.form = form;
            this.index = index;
        },
        addPiao: function (type) {
            let form;
            if (type==='zhuan'){
                form = F.get('invoiceTitle', 'add_zhuan',{type:'zhuan'});
            }else{
                form = F.get('invoiceTitle', 'add_pu',{type:'pu'});
            }
            this.actionName = 'addPiao';
            this.form = form;
        },
        removePiao:function (index){
            let id=this.tableData[index].id;
            this.$confirm('你真的要删除这个发票抬头信息吗?', '操作确认').then(() => {
                this.$bbs.delete(this.baseUrl, {data: {id}}).then(() => {
                    this.tableData.splice(index, 1);
                    this.$message.success('删除成功!');
                });
            });
        },
        deleteServer: function (index, id) {
            this.$confirm('你真的要删除这个订阅吗?', '操作确认').then(() => {
                this.$axios.delete(this.baseUrl, {data: {id}}).then(() => {
                    this.tableData.splice(index, 1);
                    this.page.total--;
                    this.$message.success('删除成功!');
                });
            });
        }
    }
}
</script>

<style lang="less" scoped>
.rank {
    display: inline-block;
    margin-right: 10px;
}
</style>
