<template>
    <div>
        <el-form  :model="data" label-position="right" label-width="80px">
            <el-row :gutter="10">
                    <template v-for="(item) in fields">
                        <el-col v-if="item.type==='daterange'" :xs="24" :sm="12" :md="12" :lg="8" :key="item.name" >
                            <el-form-item :key="item.name" :label="item.title">
                                <el-date-picker
                                        :value-format="item.valueFormat"
                                        class="my-date-picker"
                                        v-model="data[item.name]"
                                        type="daterange"
                                        range-separator="至"
                                        :picker-options="pickerOptions"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col v-else-if="item.type==='select'" :xs="24" :sm="12" :md="8" :lg="6" :key="item.name" >
                            <el-form-item :key="item.name" :label="item.title">
                                <el-select v-model="data[item.name]" :placeholder="item.placeholder">
                                    <el-option key="" value="" label="请选择"></el-option>
                                    <el-option
                                            v-for="option in item.options"
                                            :key="option.key"
                                            :label="option.label"
                                            :value="option.key"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col v-else-if="['dict_select','dict_key'].indexOf(item.type)>-1" :xs="24" :sm="12" :md="8" :lg="6" :key="item.name" >
                            <el-form-item :key="item.name" :label="item.title">
                                <el-select v-model="data[item.name]" :placeholder="item.placeholder">
                                    <el-option key="" value="" label="请选择"></el-option>
                                    <el-option
                                        v-for="v in $store.state.dict[item.dict_key]"
                                        :key="v.value"
                                        :label="v.label"
                                        :value="v.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col v-else-if="['dict_tag'].indexOf(item.type)>-1" :xs="24" :sm="12" :md="8" :lg="6" :key="item.name" >
                            <el-form-item :key="item.name" :label="item.title">
                                <el-select v-model="data[item.name]" :placeholder="item.placeholder">
                                    <el-option key="" value="" label="请选择"></el-option>
                                    <el-option
                                        v-for="v in $store.state.dict[item.dict_key]"
                                        :key="v.value"
                                        :label="v.label"
                                        :value="v.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col v-else-if="['long_search'].indexOf(item.type)>-1" :xs="24" :sm="18" :md="18" :lg="12" :key="item.name" >
                            <el-form-item :key="item.name" :label-width="item.search_width" :label="item.search_title||item.title">
                                <el-input  v-model="data[item.name]" clearable :placeholder="item.placeholder"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-else :xs="24" :sm="12" :md="8" :lg="6" :key="item.name" >
                            <el-form-item :key="item.name" :label-width="item.search_width" :label="item.search_title||item.title">
                                <el-input  v-model="data[item.name]" clearable :placeholder="item.placeholder"></el-input>
                            </el-form-item>
                        </el-col>
                    </template>
            </el-row>
        </el-form>
        <el-row :gutter="10">
         <el-col :xs="24" :sm="12" :md="8" :lg="6">
             <div  style="padding-left: 80px;">
                 <el-button type="primary" :loading="this.$store.state.loading" @click="search" size="medium" icon="el-icon-search">搜索</el-button>
             </div>
         </el-col>
            <el-col :xs="24" :sm="12" :md="16" :lg="18">
                <div style="padding-left: 80px;">
                    <slot name="left">

                    </slot>
                </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6">
                <div style="padding-left: 80px;">
                    <slot name="add">

                    </slot>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "search",
        props:{
            init:{
              default:function () {
                    return true;
              }
            },
            data:{
                default:function () {
                    return {}
                }
            },
            fields:{
                type:Array,
                require:true,
            },
            where:{
                type:Object,
                require:true,
            }
        },
        data:function () {
            return {
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                }
            };
        },
        mounted() {
            if (this.init) this.search({type:'init'});
        },
        methods:{
            search:function () {
                let data=JSON.parse(JSON.stringify(this.data));
                for(let k of this.fields){
                    switch (k.type) {
                        case "daterange":
                            if (k.valueFormat==='timestamp'){
                                if (this.data[k.name]&&this.data[k.name].length) data[k.name]=data[k.name].map(v=>Math.floor(v/1000));
                            }
                            break;
                    }
                }
                this.$emit('condition',data);
            }
        }
    }
</script>

<style scoped lang="less">
    .my-date-picker ::v-deep{
        .el-range-separator{
            padding: 0;
        }
    }
</style>
