export default {
    fields: {
        mapping_id: {type:'primary'},
        net_id: {type:'hidden'},
        from_type: {type:'hidden'},
        username: {title: '用户名',width:140},
        nickname: {title: '昵称',width:140},
        phone: {title: '手机号',width:140},
        start_time: {title: '开始时间', type: 'daterange'},
        end_time: {title: '截止时间', type: 'daterange'},
        free_sum: {title: '已免费授权次数'},
        level: {title: '会员等级', type: 'dict_key', dict_key: 'pro_member_level',width:80},
        type:{title:'操作类型',type:'dict_key',dict_key:'pro_log_type'},
        create_time: {title: '添加时间', type: 'date', format: 'Y-m-d'},
        company_name:{title:'购买公司名称'},
        is_default:{title:'是否默认',type:'dict_tag',dict_key:'is_yes_or_no',width:90,info_text:'如果用户加入了多个网络版，设置哪个优先使用'},
        bind_status:{title:'用户状态',type:'dict_tag',dict_key:'pro_bind_status',width:90},
        company_name_alias:{title:'公司名'},
    },
    rules: {
        phone: [
            {required: true, message: '手机号不可以为空', trigger: 'blur'},
        ],
    },
    group: {
        show_buy: {
            fields: ['nickname', 'phone',  'is_default','bind_status','create_time'],
        },
        show_join: {
            fields: ['company_name_alias','nickname', 'phone',  'is_default','bind_status','create_time'],
        },
        edit_user_buy:{
            fields: ['from_type','mapping_id','nickname', 'bind_status'],
            show:true,
            title:'修改用户信息'
        },
        edit_user_join:{
            fields: ['from_type','mapping_id','nickname','is_default'],
            show:true,
            title:'修改我的绑定信息'
        },
        add_user:{
            fields: ['net_id', 'phone','nickname'],
            show:true,
            title:'添加用户'
        },
        search: {
            fields: ['bind_status'],
        },
    }
}
