<template>
    <div class="base-width  rank">
        <el-row :gutter="15">
            <el-col :offset="1" :span="22">
                <el-row :gutter="15">
                    <el-col :span="8">
                        <div class="rank-item">
                            <div class="title">
                                <img src="/static/bbs/images/answer/top.png" alt="">
                                <span class="text">热榜</span>
                            </div>
                            <div class="rank-body">
                                <a target="_blank" :href="'/wenda/detail/'+item.answer_id+'.html'"
                                   v-for="(item,index) in rank.top"
                                   :key="item.answer_id" class="item">
                                    <div class="rank-title">
                                        <div class="index" :class="['index-'+(index+1)]">
                                            {{ index + 1 }}
                                        </div>
                                        <div class="rank-text">
                                            {{ item.title }}
                                        </div>
                                    </div>
                                    <div class="description bbs-second-color">
                                        {{ $tools.stripHtmlTag(item.content) }}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="rank-item">
                            <div class="title">
                                <img src="/static/bbs/images/answer/hot.png" alt="">
                                <span class="text">热点</span>
                            </div>
                            <div class="rank-body">
                                <a target="_blank" :href="'/wenda/detail/'+item.answer_id+'.html'"
                                   v-for="(item,index) in rank.hot"
                                   :key="item.answer_id" class="item">
                                    <div class="rank-title">
                                        <div class="index" :class="['index-'+(index+1)]">
                                            {{ index + 1 }}
                                        </div>
                                        <div class="rank-text">
                                            {{ item.title }}
                                        </div>
                                    </div>
                                    <div class="description bbs-second-color">
                                        {{ $tools.stripHtmlTag(item.content) }}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="rank-item">
                            <div class="ad">
                                <img src="/static/bbs/images/index/ad.png" alt="">
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import {TOP_AND_HOT_OK} from "@/utils/eventList";

export default {
    data() {
        return {
            baseUrl: '/bbs/hot/index',
            rank: {
                hot: [],
                top: [],
            }
        }
    },
    mounted() {
        this.getRank();
    },
    methods: {
        getRank() {
            this.$bbs.get(this.baseUrl, {
                params: {
                    type: 'hot,top',
                }
            }).then(data => {
                //top是一定有值的
                this.rank.top = data.data.top.slice(0, 5);
                this.rank.hot = data.data.hot.slice(0, 5);
                this.$event.$emit(TOP_AND_HOT_OK,data.data);
                let l=this.rank.hot.length;
                if (l<5){
                    this.rank.hot.push(...this.rank.top.slice(0,5-l));
                }
            });
        },
    }
}
</script>
<style scoped lang="less">
.rank {
    //display: flex;
    margin-top: 20px;
    padding: 0 8px;
    //justify-content: space-around;
    //align-items: center;

    .rank-item {
        background-color: #fff;
        padding: 25px;
        height: 400px;
        //width: 31%;
        width: 100%;
        //flex-shrink: 0;
        user-select: none;

        .ad {
            text-align: center;
            height: 100%;
            width: 100%;

            img {
                height: 100%;
                width: 100%;
            }
        }

        .title {
            display: flex;
            margin-bottom: 20px;
            align-items: center;

            img {
                height: 20px;
            }

            .text {
                padding-left: 20px;
                font-size: 18px;
                font-weight: 700;
            }
        }

        .rank-body {
            .item {
                margin-bottom: 20px;
                display: block;

                &:hover {
                    text-decoration: none;
                }

                .rank-title {
                    display: flex;
                    margin-bottom: 10px;

                    .index {
                        font-size: 18px;
                        width: 30px;
                        color: rgba(102, 102, 102, 1);
                        flex-shrink: 0;

                        &.index-1 {
                            color: rgba(223, 84, 84, 1);
                        }

                        &.index-2 {
                            color: rgba(223, 122, 84, 1);
                        }

                        &.index-3 {
                            color: rgba(223, 173, 84, 1);
                        }
                    }

                    .rank-text {
                        flex: 1;
                        color: #333;
                        overflow: hidden;
                        text-overflow: ellipsis; // 显示省略号
                        white-space: nowrap; // 不换行
                    }
                }

                .description {
                    overflow: hidden;
                    text-overflow: ellipsis; // 显示省略号
                    white-space: nowrap; // 不换行
                    padding-left: 30px;
                }
            }
        }
    }
}
</style>
