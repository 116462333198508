<template>
    <div class="profile">
        <el-row>
            <el-col :offset="8" :span="8">
                <!--                <div>-->
                <!--                    <h5 class="text-center">我的个人信息</h5>-->
                <!--                </div>-->
                <div class="item">
                    <div class="title">用户名:</div>
                    <div class="value">
                        {{ $store.state.userData.username }}
                    </div>
                </div>
                <div class="item">
                    <div class="title">昵称:</div>
                    <div class="value">
                        {{ $store.state.userData.nickname }}
                    </div>
                </div>
                <div class="item">
                    <div class="title">密码:</div>
                    <div class="value">
                        <el-link v-access="{url:baseUrl,method:'PUT'}" v-if="$store.state.userData.username" @click="editPassword" type="primary">
                            修改
                        </el-link>
                    </div>
                </div>
            </el-col>
        </el-row>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>
<script>
import F from '@/fields/fields';

export default {
    data() {
        return {
            baseUrl:'/bbs/user/password',
            form: {
                actionName: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
                index: null,
            },
        }
    },
    methods: {
        editPassword: function () {
            this.form = F.get('bbsUser', 'edit_password');
            this.form.actionName = 'editPassword';
        },
        submit: function (data) {
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }
            switch (this.form.actionName) {
                case 'editPassword':
                    this.$bbs.put(this.baseUrl, data.data,{need:{error:true,progress:true}}).then(() => {
                        this.$message.success('保存成功!');
                    });
                    break;
            }
            this.form.show = false;
        }
    }
}
</script>
<style scoped lang="less">
.profile {
    margin-top: 20px;
    background: #fff;

    h5 {
        padding: 25px;
    }

    padding: 40px 0;

    .item {
        text-align: center;
        display: flex;
        margin-bottom: 20px;

        .title {
            width: 80px;
            text-align: right;
            padding-right: 10px;
            color: #666;
        }

        .value {
            text-align: left;
            padding-left: 20px;
            flex: 1;
            color: #333;
        }
    }
}
</style>
