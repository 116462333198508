<template>
    <div class="bbs-layout">
        <div class="menu-sidebar" :class="{mini:collapseMenu}">
            <el-menu style="height: 100%" :collapse="collapseMenu" :default-active="defaultActive" router
                     active-text-color="#409EFF"
                     text-color="#bfcbd9" background-color="#304156">
                <menu-item :menu="menu"></menu-item>
            </el-menu>
        </div>
        <div class="content-layout">
            <router-view></router-view>
        </div>
        <!--        <div class="main" :class="{mini:collapseMenu}">-->
        <!--&lt;!&ndash;            <top-header>&ndash;&gt;-->
        <!--&lt;!&ndash;                <span slot="side-menu"  @click="toggleSidebar" href="javascript:void(0);">&ndash;&gt;-->
        <!--&lt;!&ndash;                        <icon-font icon-class="zhankai" class="toggle-menu" :class="{transform:collapseMenu}"></icon-font>&ndash;&gt;-->
        <!--&lt;!&ndash;                </span>&ndash;&gt;-->
        <!--&lt;!&ndash;            </top-header>&ndash;&gt;-->
        <!--            <div>-->

        <!--            </div>-->
        <!--            <a href=""  id="global_download_link"></a>-->
        <!--        </div>-->
    </div>
</template>
<script>
import storage from "@/utils/storage";
import MenuItem from '@/components/common/menu-item';

export default {
    name: "layout",
    data: function () {
        return {
            defaultActive: '',
            collapseMenu: false,
            isFullScreen: false,
        }
    },
    components: {
        MenuItem,
    },
    computed: {
        menu: function () {
            let menu = this.$store.state.permission.filter((v) => v.type === 'menu');
            return this.tools.loop(menu, 'menu_id');
        },
    },
    created() {
        //在页面刷新的时候，还原用户的信息
        let userData = storage.userData();
        this.$store.dispatch('userData', userData);
        let menu = storage.permission();
        this.$store.dispatch('permission', menu);
        this.setCurrentActive();
    },
    watch: {
        $route(to) {
            this.setCurrentActive(to.pathname);
        }
    },
    methods: {
        toggleSidebar() {
            this.collapseMenu = !this.collapseMenu;
        },
        setCurrentActive: function (path = '') {
            path = !path ? this.$route.path : path;
            for (let u of this.$store.state.permission) {
                if (u.url === path && u.type === 'menu') {
                    // console.log(u);
                    document.title = u.title;
                    this.defaultActive = u.menu_id.toString();
                    // break;
                }
            }
        },
    }
}
</script>
<style scoped lang="less">
#global_download_link {
    position: absolute;
    top: -9999px;
}

.bbs-layout {
    display: flex;
    min-height: calc(100vh - 200px);

    .content-layout {
        background: #fff;
        margin: 10px 15px;
        //box-sizing: border-box;
        padding: 10px;
        //display: none;
        flex: 1;
    }
}

.el-menu {
    border-right: none;
    overflow-x: hidden;
}

.menu-sidebar {
    //position: fixed;
    //top: 55px;
    width: 200px;
    flex-shrink: 0;
    //left: 0;
    //bottom: 0;
    overflow: auto;
    //height: 100%;
    //min-height: calc(100vh - 200px);
    //transition: all ease-in 0.25s;
    //z-index: 10;
    background-color: #304156;
}

.main-sidebar.mini {
    width: 65px;
}

.main11 {
    .header {
        border-bottom: 1px solid #ccc;
    }

    .toggle-menu {
        //margin-top: 15px;
        transition: all ease-in-out 0.2s;
        transform: rotate(-90deg);
        font-size: 1.3rem;
        color: #f1f1f1;

        &.transform {
            transform: rotate(0);
        }
    }

    transition: all ease-in 0.25s;
    //margin-left: 200px;
    .el-main-layou1t {
        overflow: unset;
        //background:url("/static/liang/images/main-bg.png") top center no-repeat;
        //background-size: 100% 100%;
        background-color: #f0f2f5;
        min-height: calc(100vh - 200px);
    }
}

.main.mini {
    margin-left: 65px;
}
</style>
