<template>
    <div>
        <div class="my-question bbs-second-color">
            <div class="title">
                创作中心
            </div>
            <div class="q-type">
                <a class="item" href="javascript:void(0);" @click.prevent="jumpProfile(item)" v-for="item in type"
                   :key="item.icon">
                    <div class="top-icon" :class="['type-'+item.name]">
                    <span>
                        <i :class="item.icon"></i>
                    </span>
                    </div>
                    <div class="item-title">
                        {{ item.title }}
                    </div>
                </a>
            </div>
            <div class="q-btn">
                <a class="jump-to-user" href="javascript:void(0);"
                   @click.prevent="jumpProfile({url:'/user/answer/all'})">
                    创作中心
                    <i class="el-icon-d-arrow-right"></i>
                </a>
            </div>
        </div>
        <connected-answer></connected-answer>
        <today-hot></today-hot>
    </div>
</template>

<script>
import TodayHot from "@/components/wenda/right/today-hot.vue";
import ConnectedAnswer from "@/components/wenda/right/connected-answer.vue";
import storage from "@/utils/storage";
import {JUMP_URL} from "@/utils/eventList";

export default {
    components: {
        TodayHot, ConnectedAnswer
    },
    data() {
        return {
            type: [
                {title: '我回答的', name: 'answer', url: '/user/answer/answer', icon: 'el-icon-question'},
                {title: '我发布的', name: 'create', url: '/user/answer/all', icon: 'el-icon-chat-dot-square'},
                {title: '我的积分', name: 'coin', url: '/user/profile/coin', icon: 'el-icon-coin'},
            ],
        }
    },
    methods: {
        jumpProfile(item) {
            let url = item && item.url;
            if (!url) return;
            let a = document.createElement('a');
            a.target = '_blank';
            a.href = url;
            storage.storage(JUMP_URL,{url:url});
            if (!this.$tools.checkLogin()) {
                return;
            }
            a.click();
        }
    }
}
</script>

<style scoped lang="less">
.my-question {
    font-size: 14px;
    background-color: #fff;
    padding: 15px 10px;
    border-radius: 5px;

    .title {
        padding: 10px 0;
    }

    .q-type {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .item {
            flex: 1;
            display: block;
            text-align: center;

            &:hover {
                text-decoration: none;
            }

            .top-icon {
                display: flex;
                align-items: center;
                margin: 10px auto;
                justify-content: center;
                padding: 10px;

                border-radius: 100%;
                width: 35px;
                background-color: #00cec9;
                height: 35px;
                font-size: 20px;

                &.type-answer {
                    color: #1C8CE5;
                    background-color: #B3D1FF;
                }

                &.type-create {
                    color: #F5CC19;
                    background-color: #FBEEB4;
                }

                &.type-coin {
                    background-color: #BDEBEB;
                    color: #42C8C8;
                }
            }

            .item-title {
                color: #333;
            }
        }
    }

    .jump-to-user {
        margin: 10px 0;
        display: flex;
        padding: 10px;
        border: 1px solid #1C8CE5;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        flex: 1;

        &:hover {
            text-decoration: none;
        }
    }
}
</style>
