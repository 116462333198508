<template>
    <div style="background-color: #fff">
        <div class="pl-4" style="padding-top: 24px">
            <el-radio-group v-model="my_net_type">
                <el-radio label="buy">我购买的</el-radio>
                <el-radio label="join">我加入的</el-radio>
            </el-radio-group>
        </div>
        <div v-if="my_net_type==='buy'">
            <el-divider></el-divider>
            <div class="pt-3 pl-3">
                <el-button @click="addUser" :disabled="add_disabled" v-access="{url:baseAddUrl,method:'POST'}"
                           size="medium"
                           icon="el-icon-plus" type="primary">添加用户
                </el-button>
            </div>
            <el-divider content-position="left">购买基本信息</el-divider>
            <div class="mt-1">
                <my-table row-key="product_id" :data="companyData" :fields="showFields">
                    <el-table-column slot="operation" width="150" label="操作">
                        <template slot-scope="scope">
                            <!--                        <span class="my-sort-dom" :data-id="scope.row.product_id">-->
                            <!--                            <i class="el-icon-rank"></i>-->

                            <!--                        </span>-->
                            <!--                        <el-button-->
                            <!--                            size="mini"-->
                            <!--                            :data-id="scope.row.product_id"-->
                            <!--                            class="my-sort-dom"-->
                            <!--                            v-access="{url:baseUrl,method:'PUT'}"-->
                            <!--                            icon="el-icon-rank">-->
                            <!--                        </el-button>-->
                            <el-button
                                size="mini"
                                @click="editCompany(scope.$index)" v-access="{url:baseUrl,method:'PUT'}"
                                icon="el-icon-edit"></el-button>
                        </template>
                    </el-table-column>
                </my-table>
            </div>
        </div>
        <div class="pt-3 pt-3">
            <el-divider content-position="left">{{ my_net_type === 'buy' ? '用户列表信息' : '我加入的网络版列表' }}
            </el-divider>
        </div>
        <div class="mt-3">
            <join-list @handle="handleUserClick" :net_type="my_net_type"
                       :users="users"></join-list>
        </div>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>
<script>
import F from '@/fields/fields';
import search from "@/components/common/search";
import MyTable from "@/components/common/my-table";
import JoinList from "@/components/user/buy/join-list.vue";
// import Sortable from 'sortablejs';

export default {
    components: {
        search, MyTable, JoinList,
    },
    created() {

    },
    mounted() {
        this.search();
    },
    data: function () {
        return {
            baseUrl: '/bbs/net/list',
            baseAddUrl: '/bbs/net/jo',
            baseManageUrl: '/bbs/net/rma',
            my_net_type: 'buy',
            page: {
                search: {},
                sort: {},
                total: 1,
                pageSize: 15,
                pageNumber: 1,
            },
            users: [],
            companyDetail: {
                net_sum: 0,
                company_name_alias: '',
                net_id: '',
            },
            form: {
                action_name: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            }
        }
    },
    watch: {
        my_net_type: function () {
            this.search();
        },
    },
    computed: {
        searchFields: function () {
            return F.search('proNetList', 'search');
        },
        showFields: function () {
            return F.show('proNetList', 'show');
        },
        companyData: function () {
            if (!this.companyDetail.net_id) return [];
            return [this.companyDetail];
        },
        add_disabled: function () {
            let can = this.companyDetail.net_id && this.companyDetail.net_sum && this.users.length < this.companyDetail.net_sum;
            return !can;
        },
    },
    methods: {
        // condition: function (search) {
        //     this.page.search = search;
        //     this.page.pageNumber = 1;
        //     this.search();
        // },
        search: function () {
            this.users.splice(0,100000);
            let current_type = this.my_net_type;
            let url = current_type === 'buy' ? this.baseUrl : this.baseAddUrl;
            this.$bbs.get(url, {
                params: this.page,
            }).then(data => {
                if (data.data.detail && current_type === 'buy') {
                    this.companyDetail = data.data.detail;
                    this.users = data.data.users || [];
                } else {
                    this.users = data.data.data || [];
                }
                // console.log(data);
                // this.tableData = data.data;
            });
        },
        sortChange: function (column) {
            if (!column.order) return;
            this.page.sort = {prop: column.prop, order: column.order};
            this.search();
        },
        sizeChange: function (size) {
            this.page.pageSize = size;
            this.search();
        },
        pageChange: function (page) {
            this.page.pageNumber = page;
            this.search();
        },
        filterPlatform(value, row) {
            return row.tag === value;
        },
        submit: function (data) {
            // this.con(data);
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }
            switch (this.actionName) {
                case 'editCompany':
                    this.$bbs.put(this.baseUrl, data.data, {
                        need: {
                            error: true,
                        }
                    }).then(adData => {
                        this.$message.success('保存成功!');
                        this.companyDetail = adData.data;
                        this.form.show = false;
                    });
                    break;
                case 'addUser':
                    this.$bbs.post(this.baseAddUrl, data.data, {
                        need: {
                            error: true,
                        }
                    }).then(userData => {
                        this.page.total++;
                        this.form.show = false;
                        this.$message.success('添加成功!');
                        this.users.unshift(userData.data);
                    });
                    break;
                case 'editUser':
                    //这里添加这个变量，方便后台判断是哪里来的修改操作，方便判断
                    data.data.from_type = this.my_net_type;
                    data.data.net_id = this.companyDetail.net_id;
                    this.$bbs.put(this.baseAddUrl, data.data, {
                        need: {
                            error: true,
                        }
                    }).then(userData => {
                        this.form.show = false;
                        this.$message.success('修改成功!');
                        //先获取原来的旧数据
                        let old_data=this.users[this.index];
                        //还原对应的手机号和公司的名称
                        userData.data.phone=old_data.phone;
                        userData.data.company_name_alias=old_data.company_name_alias;
                        this.users.splice(this.index, 1, userData.data);
                    });
                    break;
            }
        },
        editCompany: function (index) {
            let form = F.get('proNetList', 'edit_company', this.companyData[index]);
            this.actionName = 'editCompany';
            this.form = form;
            this.index = index;
        },
        addUser: function () {
            let form = F.get('proNetMapping', 'add_user', {net_id: this.companyDetail.net_id});
            this.actionName = 'addUser';
            this.form = form;
        },
        handleUserClick(info) {
            if (info.type === 'edit') {
                let form;
                if (this.my_net_type==='buy'){
                    form = F.get('proNetMapping', 'edit_user_buy', this.users[info.index]);
                }else{
                    form = F.get('proNetMapping', 'edit_user_join', this.users[info.index]);
                }
                this.actionName = 'editUser';
                this.index = info.index;
                this.form = form;
            }else  if (info.type==='remove'){
                this.$confirm('你真的要删除这个用户吗?', '操作确认').then(() => {
                    this.$bbs.delete(this.baseUrl, {data: {id:this.users[info.index].mapping_id}}).then(() => {
                        this.users.splice(info.index, 1);
                        this.$message.success('删除成功!');
                    });
                });
            }else if (info.type==='manage'){
                this.$confirm('你确认要设置此人为管理员吗？确认后您将不能管理此网络版的用户', '设置管理员').then(() => {
                    this.$confirm('确认后您将不能管理此网络版的用户', '操作确认').then(()=>{
                        this.$bbs.put(this.baseManageUrl, {net_id:this.companyDetail.net_id,username:this.users[info.index].username}).then(() => {
                            this.$message.success('操作成功!');
                            setTimeout(()=>{
                                    location.reload();
                            },1000);
                        });
                    });
                });
            }
        },
        deleteServer: function (index, id) {
            this.$confirm('你真的要删除这个订阅吗?', '操作确认').then(() => {
                this.$axios.delete(this.baseUrl, {data: {id}}).then(() => {
                    this.tableData.splice(index, 1);
                    this.page.total--;
                    this.$message.success('删除成功!');
                });
            });
        }
    }
}
</script>

<style lang="less" scoped>
.rank {
    display: inline-block;
    margin-right: 10px;
}
</style>
