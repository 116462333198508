export default {
    fields: {
        net_id: {type:'primary'},
        username: {title: '用户名',width:140},
        net_sum:{title:'节点数',width:70},
        start_time: {title: '开始时间', type: 'daterange'},
        end_time: {title: '截止时间', type: 'daterange'},
        free_sum: {title: '已免费授权次数'},
        level: {title: '会员等级', type: 'dict_key', dict_key: 'pro_member_level',width:80},
        type:{title:'操作类型',type:'dict_key',dict_key:'pro_log_type'},
        create_time: {title: '购买时间', type: 'date', format: 'Y-m-d H:i:s'},
        company_name:{title:'购买公司名称'},
        bind_status:{title:'用户状态',type:'dict_tag',dict_key:'pro_bind_status',width:90},
        company_name_alias:{title:'公司名'},
    },
    rules: {

    },
    group: {
        show: {
            fields: ['company_name_alias','net_sum', 'start_time',  'end_time','create_time'],
        },
        edit_company:{
            fields: ['net_id', 'company_name_alias'],
            show:true,
            title:'修改公司信息'
        },

        search: {
            fields: ['bind_status'],
        },
    }
}
