import Vue from 'vue';
import VueRouter from "vue-router";
import Cookie from 'js-cookie';
import storage from "@/utils/storage";
import tools from "@/utils/tools";
import cn from './cn';
import com from "@/router/com";

Vue.use(VueRouter);
let routes = [
    {
        path: '/login',
        component: () => import('@/components/login/index'),
    },
    {
        path: '/login/jump',
        component: () => import('@/components/login/jump'),
    },
    {
        path: '/function',
        component: () => import('@/components/help/function'),
    },
    {
        path: '/',
        component: () => import('@/components/common/layout'),
        redirect: '/index',
        children: [
            {
                path: 'index',
                component: () => import('@/components/index/index.vue'),
            },

            {
                path: 'index/search',
                component: () => import('@/components/index/search.vue'),
            },
            {
                path: 'add',
                component: () => import('@/components/wenda/add.vue'),
            },
            {
                path: 'wenda',
                redirect: '/wenda/index',
                component: () => import('@/components/wenda/index.vue'),
                children: [
                    {
                        path: 'detail/:id',
                        component: () => import('@/components/wenda/left/detail.vue'),
                    },
                    {
                        path: ':type',
                        component: () => import('@/components/wenda/left/question-list.vue'),
                    },
                    // {
                    //     path:'unanswered',
                    //     meta: {
                    //         type: 'unanswered',
                    //     },
                    //     component: () => import('@/components/wenda/left/question-list.vue'),
                    // },
                    // {
                    //     path:'done',
                    //     meta: {
                    //         type: 'done',
                    //     },
                    //     component: () => import('@/components/wenda/left/question-list.vue'),
                    // }
                ]
            },
            {
                path: 'user',
                redirect: '/user/profile/setting',
                component: () => import('@/components/user/layout.vue'),
                children: [
                    {
                        path:'profile',
                        component: () => import('@/components/user/right.vue'),
                        children:[
                            {
                                path:'setting',
                                component: () => import('@/components/user/profile/index.vue'),
                            },
                            {
                                path:'coin',
                                component: () => import('@/components/user/profile/index.vue'),
                            }
                        ],
                    },

                    {
                        path:'setting/menu',
                        component: () => import('@/components/settings/menu.vue'),
                    },
                    {
                        path:'list',
                        component: () => import('@/components/user/list.vue'),
                    },
                    {
                        path:'buy/net',
                        component: () => import('@/components/user/buy/net.vue'),
                    },
                    {
                        path:'buy/invoice',
                        name:'pro_invoice',
                        component: () => import('@/components/user/buy/invoice.vue'),
                    },
                    {
                        path:'buy/invoicetitle',
                        component: () => import('@/components/user/buy/invoice_title.vue'),
                    },

                    {
                        path:'buy/list',
                        component: () => import('@/components/user/buy/list.vue'),
                    },
                    {
                        path: 'usergroup',
                        component: () => import('@/components/user/usergroup.vue')
                    },
                    {
                        path:'answer/all',
                        component: () => import('@/components/user/answer/all.vue'),
                    },
                    {
                        path:'answer/sum',
                        component: () => import('@/components/user/answer/sum.vue'),
                    },
                    {
                        path:'answer/answer',
                        component: () => import('@/components/user/answer/answer.vue'),
                    },
                    {
                        path:'answer/:type',
                        component: () => import('@/components/user/answer/my.vue'),
                    },
                    {
                        path: 'verify/reply',
                        component: () => import('@/components/user/verify/reply.vue'),
                    },
                    {
                        path: 'verify/comment',
                        component: () => import('@/components/user/verify/comment.vue'),
                    },
                    // {
                    //     path: 'verify/:type',
                    //     component: () => import('@/components/user/verify/index.vue'),
                    // },
                ]
            }
        ]
    },
    {
        path: '/wenda',
        redirect: '/wenda/index',
        component: () => import('@/components/common/layout'),
        children: [
            {
                path: 'detail',
                component: () => import('@/components/wenda/left/left-side.vue'),
                children: [
                    {
                        path: 'index',
                        alias: '/wenda/index',
                        meta: {
                            type: 'index',
                        },
                        component: () => import('@/components/wenda/index')
                    },
                    {
                        path: 'unanswered',
                        meta: {
                            type: 'unanswered',
                        },
                        component: () => import('@/components/wenda/index')
                    },
                ],
            },
            {
                path: 'done',
                meta: {
                    type: 'done',
                },
                component: () => import('@/components/wenda/index')
            },
            // {
            //     path: 'detail/:id',
            //     meta: {
            //
            //     },
            //     component: () => import('@/components/wenda/detail')
            // },
            // {
            //     path: 'user/chart',
            //     component: () => import('@/components/wxaudit/audit')
            // },
            // {
            //     path: 'user/system',
            //     component: () => import('@/components/app/user/system')
            // },
            // {
            //     path: 'user/online',
            //     component: () => import('@/components/app/userOnline')
            // },
            // {
            //     path: 'ad',
            //     component: () => import('@/components/app/appAd')
            // },
            // {
            //     path: 'pcad',
            //     component: () => import('@/components/app/appPcAd'),
            // },
            // {
            //     path: 'pcadadd/:id',
            //     meta: {
            //         url: '/app/pcad',
            //     },
            //     component: () => import('@/components/app/appPcAdAdd')
            // },
            // {
            //     path: 'sms',
            //     component: () => import('@/components/app/appSms')
            // },
            // {
            //     path: 'comment',
            //     component: () => import('@/components/app/comment')
            // },
            // {
            //     path: 'groupsending',
            //     component: () => import('@/components/app/groupsending')
            // },
            // {
            //     path: 'user/app',
            //     component: () => import('@/components/app/userApp')
            // },
            // {
            //     path: 'user/clear',
            //     component: () => import('@/components/app/userClear')
            // },
            // {
            //     path: 'user/report',
            //     component: () => import('@/components/app/userReport')
            // },
            // {
            //     path: 'user/usernet',
            //     component: () => import('@/components/app/userNet')
            // },
            // {
            //     path: 'user/profile',
            //     component: () => import('@/components/app/profile')
            // },
            // {
            //     path: 'chart/manage',
            //     component: () => import('@/components/chart/manage')
            // },
            // {
            //     path: 'chart/:phone',
            //     meta: {
            //         url: '/app/chart',
            //     },
            //     component: () => import('@/components/chart/chart')
            // },
            // {
            //     path: '/bug/list',
            //     component: () => import('@/components/Bug/bugList')
            // },
            // {
            //     path: '/bug/addset',
            //     component: () => import('@/components/Bug/addset')
            // },
            // {
            //     path: '/bug/addbug',
            //     component: () => import('@/components/Bug/addbug')
            // },
            // {
            //     path: 'pay/list',
            //     component: () => import('@/components/pay/list')
            // },
            // {
            //     path: 'pay/refund',
            //     component: () => import('@/components/pay/refund')
            // },
            // {
            //     path: 'pay/freegis',
            //     component: () => import('@/components/pay/freegis')
            // },
            // {
            //     path: 'pay/sum',
            //     component: () => import('@/components/pay/sum')
            // },
            // {
            //     path: 'pay/product',
            //     component: () => import('@/components/pay/product')
            // },
            // {
            //     path: 'pay/gis',
            //     component: () => import('@/components/pay/gis')
            // }
        ]
    },
    {
        path: '/setting',
        redirect: '/setting/index',
        component: () => import('@/components/common/layout'),
        children: [
            {
                path: 'index',
                component: () => import('@/components/settings/index')
            },
            {
                path: 'menu',
                component: () => import('@/components/settings/menu')
            },
        ]
    },

    // {
    //     path: '/tmptmp1',
    //     component: () => import('@/components/history/subscribe')
    // },
    {
        path: '/401',
        component: () => import('@/components/error/401')
    },
    {
        path: '*',
        component: () => import('@/components/error/404')
    },
];

let routers = new VueRouter({
    mode: 'history',
    // mode: 'hash',
    routes
});

routers.beforeEach(function (to, from, next) {
    // let token = storage.token();
    // if (!token&&to.path.indexOf('/user')>-1){
    //     next(false);
    //     return;
    // }
    let login = Cookie.get(process.env.VUE_APP_COOKIE), token = storage.token();
    // if (to.path==='/tmptmp1'){
    //     next();
    //     return;
    // }else{
    //     routers.push({ path: '/tmptmp1' });
    //     next();
    //     return;
    // }

    // if (!login || !token) {
    //     storage.clear();
    //     if (to.path === '/login') {
    //         next();
    //     } else {
    //         routers.push({path: '/login'});
    //     }
    // }
    // if (to.path === '/login') {
    //     storage.clear();
    // } else {
    //     if (!tools.checkPermission({
    //         url: to.meta.url || to.path,
    //         method: ['menu', 'hidden_menu']
    //     }) && ['/401', '/404', '*'].indexOf(to.path) === -1) {
    //         routers.push({path: '/401'});
    //     }
    // }

    next();
});
export default routers;
