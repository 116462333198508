<template>
    <div>
        <el-row :gutter="10">
            <el-col :span="24">
                <el-tree
                    :expand-on-click-node="false"
                    ref="treeMenu"
                    :data="treeData"
                    node-key="menu_id"
                    :props="{label:'title'}"
                    default-expand-all
                    @node-drop="nodeDrop"
                    draggable>
            <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
        <span class="link-group">
            <el-link v-access="{url:baseUrl,method:'POST'}" class="operation" type="primary"
                     @click.native.prevent="addMenu(data)">
                 <i class="el-icon-plus"></i>
                添加
            </el-link>
            <el-link v-access="{url:baseUrl,method:'PUT'}" class="operation" type="primary" @click="editMenu(data)">
                 <i class="el-icon-edit-outline"></i>
                编辑
            </el-link>
            <el-link class="operation" type="danger" v-access="{url:baseUrl,method:'delete'}" @click="deleteMenu(data)">
                 <i class="el-icon-remove"></i>
                删除
            </el-link>
        </span>
      </span>
                </el-tree>
            </el-col>
        </el-row>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>

<script>
import F from '@/fields/fields';
import tools from "@/utils/tools";

export default {
    name: "menus",
    data() {
        return {
            baseUrl: '/bbs/setting/menu',
            baseMenuTypeUrl: '/bbs/setting/menuType',
            action_name: '',
            index: '',
            menu: {
                all: [],
                type: [],
                active: '',
                menu: [],
            },
            form: {
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            }
        }
    },
    created() {
        this.$bbs.get(this.baseUrl).then((menu) => {
            this.menu.all = menu.data.menu;
        });
    },
    computed: {
        treeData: function () {
            let loopData = this.menu.all.map((v, index) => {
                v._index = index;
                return v;
            });
            return tools.loop(loopData, 'menu_id');
        }
    },
    methods: {
        submit: function (data) {
            if (data.type === 'cancel') {
                this.action_name = '';
                this.form.show = false;
                return;
            }
            switch (this.action_name) {
                case 'addMenu':
                    data.data.name = this.menu.active;
                    this.$bbs.post(this.baseUrl, data.data).then((menu) => {
                        this.menu.all.push(menu.data.current);
                        this.$store.dispatch('permission', JSON.parse(JSON.stringify(this.menu.all)));
                        this.$message.success('添加成功!');
                        this.form.show = false;
                    });
                    break;
                case 'editMenu':
                    this.$bbs.put(this.baseUrl, data.data).then((menu) => {
                        if (!menu) return;
                        let newData = Object.assign({}, this.menu.all[this.index], menu.data.current);
                        this.menu.all.splice(this.index, 1, newData);
                        this.$store.dispatch('permission', menu.data.menus);
                        this.$message.success('修改成功!');
                        this.form.show = false;
                    });
                    break;
                case 'addMenuType':
                    this.$bbs.post(this.baseMenuTypeUrl, data.data).then((menu) => {
                        this.menu.type.push(menu.data);
                        this.$message.success('添加成功!');
                        this.form.show = false;
                    });
                    break;
                case 'editMenuType':
                    this.$bbs.put(this.baseMenuTypeUrl, data.data).then((menu) => {
                        this.menu.type.splice(this.form.index, 1, menu.data);
                        this.$message.success('修改成功!');
                        this.form.show = false;
                    });
                    break;
            }
        },
        nodeDrop(a, b, c) {
            let order = {type: 'order', data: []}, parent;
            if (c === 'inner') {
                parent = b;
            } else {
                parent = b.parent;
            }
            for (let k = 0; k < parent.childNodes.length; k++) {
                if (a.data.menu_id == parent.childNodes[k].data.menu_id) {
                    order.data.push({
                        menu_id: parent.childNodes[k].data.menu_id,
                        pid: parent.data.menu_id ? parent.data.menu_id : 0,
                        sort_order: k
                    });
                } else {
                    order.data.push({menu_id: parent.childNodes[k].data.menu_id, sort_order: k});
                }
            }
            this.$bbs.put(this.baseUrl, order).then((menu) => {
                if (!menu) return;
                this.$message.success('保存成功!');
                this.$store.dispatch('permission', menu.data.menus);
            });
        },
        addMenu(data) {
            this.form = F.get('menu', 'add_menu');
            this.form.data['pid'] = data.menu_id;
            this.action_name = 'addMenu';
        },
        editMenu(data) {
            this.index = data._index;
            this.form = F.get('menu', 'edit_menu', data);
            this.action_name = 'editMenu';
        },
        deleteMenu(menu) {
            this.$confirm('你真的要删除这个菜单吗?', '操作确认', {
                type: 'warning'
            }).then(() => {
                this.$bbs.delete(this.baseUrl,{
                    data:{
                        id:menu.menu_id
                    }
                }).then(() => {
                    this.data.splice(menu._index, 1);
                    this.$store.dispatch('permission', JSON.parse(JSON.stringify(this.data)));
                }).catch(() => {

                });
            }).catch(() => {
            });
        },
        addMenuType() {
            this.form = F.get('menuType', 'add_menu');
            this.action_name = 'addMenuType';
        },
        test(item, index) {
            this.form = F.get('menuType', 'edit_menu', item);
            this.form.index = index;
            this.action_name = 'editMenuType';
        }
    }
}
</script>

<style scoped lang="less">
.el-tree-node.is-drop-inner .el-tree-node__content .custom-tree-node {
    background-color: #409EFF;
}

.el-tabs--border-card {
    border-bottom: none;
}

.el-tabs {
    user-select: none;
}

.custom-tree-node {
    font-size: 14px;

    &:hover {
        .link-group {
            opacity: 1;
            transform: translate(0px, 0px);
        }
    }

    .link-group {
        transition: all ease-in-out 0.4s;
        padding-left: 3em;
        transform: translate(100px, 0px);
        opacity: 0;

        .operation {
            margin-left: 10px;
        }
    }
}
</style>
