import { render, staticRenderFns } from "./editor.vue?vue&type=template&id=7023beb2&scoped=true"
import script from "./editor.vue?vue&type=script&lang=js"
export * from "./editor.vue?vue&type=script&lang=js"
import style0 from "./editor.vue?vue&type=style&index=0&id=7023beb2&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7023beb2",
  null
  
)

export default component.exports