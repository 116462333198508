export default {
    fields: {
        username: {title: '用户名', placeholder: '支持手机号,用户名', type: 'long_search',width:140},
        net_id:{type:'hidden',title:'公司ID'},
        start_time: {title: '开始时间', type: 'daterange'},
        end_time: {title: '截止时间', type: 'daterange'},
        free_sum: {title: '已免费授权次数'},
        _origin_class:{title:'原始权限',width:80},
        level: {title: '会员等级', type: 'dict_key', dict_key: 'pro_member_level',width:80},
        done_staff_name: {title: '操作人员'},
        _is_tmp:{title:'是否游客',type:'dict_key',dict_key:'pro_is_visitor'},
        type:{title:'操作类型',type:'dict_key',dict_key:'pro_log_type'},
        create_time: {title: '注册时间', type: 'date', format: 'Y-m-d H:i:s'},
        company_name:{title:'购买公司名称'},
        bind_status:{title:'启用情况',type:'dict_tag',dict_key:'pro_bind_status',width:90},
        company_name_alias:{title:'客户备注公司名'},
        net_sum:{title:'节点数',width:70},
    },
    rules: {

    },
    group: {
        show: {
            fields: ['username','_is_tmp', 'level', 'create_time', 'end_time'],
        },
        show_all: {
            fields: ['username', 'level','free_sum', 'create_time', 'end_time'],
        },
        net_fields:{
            fields: ['net_id',{name:'username',title:'购买人ID'},'company_name','company_name_alias','net_sum', 'create_time', 'end_time'],
        },
        net_user_fields:{
            fields: ['username','company_name','company_name_alias','bind_status', 'create_time', 'end_time'],
        },
        editNum:{
            fields: ['net_id', 'net_sum'],
            show:true,
            title:'修改节点数'
        },
        log_show: {
            fields: ['type',{name: 'level', title: '授权类型'}, 'done_staff_name', {
                name: 'end_time',
                title: '当次授权截止时间'
            }, {name: 'create_time', title: '操作时间'}],
        },
        bind_add: {
            show: true,
            title: '手动绑定',
            fields: ['hardware_no', 'phone'],
        },
        search: {
            fields: ['username'],
        },
    }
}
