<template>
    <div>
        <nav-header></nav-header>
        <router-view></router-view>
        <login-dialog></login-dialog>
        <editor></editor>
        <system-footer v-if="needFooter"></system-footer>
    </div>
</template>
<script>
import screenfull from 'screenfull';
import storage from "@/utils/storage";
import SystemFooter from "@/components/common/system-footer";
import LoginDialog from "@/components/login/login-dialog.vue";
import NavHeader from "@/components/common/nav-header.vue";
import Editor from "@/components/common/editor.vue";

export default {
    name: "layout",
    data: function () {
        return {
            needFooter:true,
            mainLoaded: false,
            baseNavUrl: '/bbs/nav/index',
            defaultActive: '',
            collapseMenu: false,
            isFullScreen: false,
        }
    },
    components: {
        SystemFooter,NavHeader,LoginDialog,Editor
    },
    computed: {
        menu: function () {
            let menu = this.$store.state.permission.filter((v) => v.type === 'menu' && v.name === this.topMenu.active);
            return this.tools.loop(menu, 'menu_id');
        },
    },
    created() {
        this.needFooter=this.$route.path!=='/add';
        //在页面刷新的时候，还原用户的信息
        let userData = storage.userData();
        if (!userData) return;
        this.$store.dispatch('userData', userData);
        let menu = storage.permission();
        // console.log(menu);
        // if (!menu) return;
        this.$store.dispatch('permission',menu);
        // let current = {};
        // let allNav = {};
        // let allType=storage.storage('menu_type');
        // allType.map(v => {
        //     allNav[v.name] = v;
        // });
        // menu.map(v => {
        //     if (!current[v.name] && allNav[v.name]) current[v.name] = allNav[v.name];
        // });
        // this.topMenu.list = Object.values(current);
        // this.topMenu.active=current['app']?'app':Object.keys(current).shift();
        // this.setCurrentActive();
        //重新刷新权限
        this.$bbs.get('/bbs/api/me').then((menu) => {
            this.$store.dispatch('permission', menu.data.menu);
        });
    },
    watch: {
        $route(to) {
            this.needFooter=to.path!=='/add';
            // this.setCurrentActive(to.pathname);
        }
    },
    methods: {
        toggleSidebar() {
            this.collapseMenu = !this.collapseMenu;
        },
        setCurrentActive: function (path = '') {
            path = !path ? this.$route.path : path;
            for (let u of this.$store.state.permission) {
                if (u.url === path || this.$route.meta && this.$route.meta.url && this.$route.meta.url === u.url) {
                    document.title = u.title;
                    this.topMenu.active = u.name;
                    this.defaultActive = u.menu_id.toString();
                    break;
                }
            }
        },
        toggleFullScreen: function () {
            this.isFullScreen = !screenfull.isFullscreen;
            screenfull.toggle();
        },
        setManage(name) {
            this.topMenu.active = name;
            let menu = this.$store.state.permission.filter((v) => v.type === 'menu' && v.name === this.topMenu.active);
            if (menu.length) {
                menu[0].url && this.$router.push(menu[0].url);
            }
        }
    }
}
</script>
<style scoped lang="less">

</style>
