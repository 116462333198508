<template>
    <div>
        <my-table row-key="product_id" :data="userList" :fields="showFields">
            <el-table-column slot="operation" width="180" label="操作">
                <template slot-scope="scope">
                    <!--                        <span class="my-sort-dom" :data-id="scope.row.product_id">-->
                    <!--                            <i class="el-icon-rank"></i>-->

                    <!--                        </span>-->
                    <!--                    <el-button-->
                    <!--                        size="mini"-->
                    <!--                        :data-id="scope.row.product_id"-->
                    <!--                        class="my-sort-dom"-->
                    <!--                        v-access="{url:baseUrl,method:'PUT'}"-->
                    <!--                        icon="el-icon-rank">-->
                    <!--                    </el-button>-->
                    <el-button
                        size="mini"
                        @click="editUser(scope.$index)" v-access="{url:baseUrl,method:'PUT'}"
                        icon="el-icon-edit"></el-button>
                    <el-button
                        v-if="net_type==='buy'"
                        size="mini"
                        type="warning"
                        @click="changeManage(scope.$index)" v-access="{url:baseManageUrl,method:'PUT'}"
                        icon="el-icon-user"></el-button>
                    <el-button
                        v-if="net_type==='buy'"
                        size="mini"
                        type="danger"
                        @click="removeUser(scope.$index)" v-access="{url:baseUrl,method:'DELETE'}"
                        icon="el-icon-remove"></el-button>
                </template>
            </el-table-column>
        </my-table>
    </div>
</template>
<script>
import F from "@/fields/fields";

export default {
    props: {
        net_type: {
            type: String,
            default: '',
        },
        users: {
            type: Array,
            default: function () {
                return [];
            }
        }
    },
    data() {
        return {
            baseUrl: '/bbs/net/jo',
            baseManageUrl: '/bbs/net/rma',
            userList: [],
        }
    },
    watch: {
        users: function () {
            this.updateUser();
        }
    },
    computed: {
        showFields: function () {
            if (this.net_type==='buy'){
                return F.show('proNetMapping', 'show_buy');
            }else{
                return F.show('proNetMapping', 'show_join');
            }
        },
    },
    methods: {
        updateUser: function () {
            this.userList.splice(0, 10000);
            this.users.map((v, index) => {
                // this.$set(v,'company_name_alias',this.company_name);
                this.userList.push(v);
            });
        },
        editUser: function (index) {
            // console.log(this.users);
            // console.log(this.userList);
            this.$emit('handle', {type: 'edit', index});
        },
        changeManage: function (index) {
            this.$emit('handle', {type: 'manage', index});
        },
        removeUser: function (index) {
            this.$emit('handle', {type: 'remove', index});
        }
    }
}
</script>
<style scoped lang="less">

</style>