<template>
    <div style="background-color: #fff">
        <div class="pt-3">
            <search @condition="condition" :fields="searchFields">
                <!--                <el-button @click="addProduct" v-access="{url:baseUrl,method:'POST'}" size="medium" slot="add"-->
                <!--                           icon="el-icon-plus" type="primary">新增-->
                <!--                </el-button>-->
            </search>
        </div>
        <div id="tableList" class="mt-3">
            <my-table row-key="product_id" :data="tableData" :fields="showFields">
                <el-table-column slot="operation" width="150" label="操作">
                    <template slot-scope="scope">
                        <!--                        <span class="my-sort-dom" :data-id="scope.row.product_id">-->
                        <!--                            <i class="el-icon-rank"></i>-->

                        <!--                        </span>-->
                        <!--                        <el-button-->
                        <!--                            size="mini"-->
                        <!--                            :data-id="scope.row.product_id"-->
                        <!--                            class="my-sort-dom"-->
                        <!--                            v-access="{url:baseUrl,method:'PUT'}"-->
                        <!--                            icon="el-icon-rank">-->
                        <!--                        </el-button>-->
                        <el-link @click="downloadInvoice(scope.row.download_link)" v-if="scope.row.status===2"
                                 type="primary">
                            下载
                        </el-link>
                        <!--                        <el-button-->
                        <!--                            size="mini"-->
                        <!--                            @click="editAd(scope.$index)" v-access="{url:baseUrl,method:'PUT'}"-->
                        <!--                            icon="el-icon-edit"></el-button>-->
                    </template>
                </el-table-column>
            </my-table>
        </div>
        <div class="pb-3 mt-3">
            <el-pagination
                @size-change="sizeChange"
                @current-change="pageChange"
                :current-page="page.pageNumber"
                :page-sizes="[10, 15, 30, 50]"
                :page-size="page.pageSize"
                :background="true"
                layout="sizes,prev, pager,next,total"
                :total="page.total">
            </el-pagination>
        </div>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>
<script>
import F from '@/fields/fields';
import search from "@/components/common/search";
import MyTable from "@/components/common/my-table";

export default {
    name: "appSms",
    components: {
        search, MyTable
    },
    created() {

    },
    mounted() {

    },
    data: function () {
        return {
            baseUrl: '/bbs/invoice/piao',
            page: {
                search: {},
                sort: {},
                total: 1,
                pageSize: 15,
                pageNumber: 1,
            },
            sum: {},
            subscribe: [],
            userGroup: [],
            tableData: [],
            form: {
                action_name: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            }
        }
    },
    computed: {
        searchFields: function () {
            return F.search('proInvoiceList', 'search');
        },
        showFields: function () {
            return F.show('proInvoiceList', 'show');
        },
    },
    methods: {
        condition: function (search) {
            this.page.search = search;
            this.page.pageNumber = 1;
            if (this.$route.params.id) {
                //从购买列表单击发票查看,跳转过来的时候，会传递一个id过来，
                this.page.search.invoice_id = this.$route.params.id;
            }
            this.search();
        },
        search: function () {
            this.$bbs.get(this.baseUrl, {
                params: this.page,
            }).then(data => {
                this.tableData = data.data.data;
                this.page.total = data.data.total;
                this.page.pageNumber = data.data.pageNumber;
                this.page.pageSize = data.data.pageSize;
            });
        },
        sortChange: function (column) {
            if (!column.order) return;
            this.page.sort = {prop: column.prop, order: column.order};
            this.search();
        },
        sizeChange: function (size) {
            this.page.pageSize = size;
            this.search();
        },
        pageChange: function (page) {
            this.page.pageNumber = page;
            this.search();
        },
        filterPlatform(value, row) {
            return row.tag === value;
        },
        submit: function (data) {
            // this.con(data);
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }
            switch (this.actionName) {
                case 'editAd':
                    this.$bbs.put(this.baseUrl, data.data).then(adData => {
                        this.$message.success('保存成功!');
                        this.tableData.splice(this.index, 1, adData.data);
                    });
                    break;
                case 'addProduct':
                    this.$bbs.post(this.baseUrl, data.data).then(userData => {
                        this.page.total++;
                        this.$message.success('创建成功!');
                        this.tableData.unshift(userData.data);
                    });
                    break;
            }
            this.form.show = false;
        },
        editAd: function (index) {
            let form = F.get('productList', 'edit', this.tableData[index]);
            this.actionName = 'editAd';
            this.form = form;
            this.index = index;
        },
        addProduct: function () {
            let form = F.get('productList', 'add');
            this.actionName = 'addProduct';
            this.form = form;
        },
        downloadInvoice: function (url) {
            if (!url) {
                return this.$message.warning('下载失败!');
            }
            let a = document.createElement('a');
            a.target = '_blank';
            a.href = url;
            a.click();
        },
        deleteServer: function (index, id) {
            this.$confirm('你真的要删除这个订阅吗?', '操作确认').then(() => {
                this.$axios.delete(this.baseUrl, {data: {id}}).then(() => {
                    this.tableData.splice(index, 1);
                    this.page.total--;
                    this.$message.success('删除成功!');
                });
            });
        }
    }
}
</script>

<style lang="less" scoped>
.rank {
    display: inline-block;
    margin-right: 10px;
}
</style>
