export default {
    fields: {
        order_id: {type:'primary',title:'商户订单号'},
        transaction_id: {title:'业务流水号'},
        username: {title: '用户名'},
        phone: {title: '电话',width:150},
        platform: {title: '平台',width:100,type:'dict_key',dict_key:'pro_platform_list'},
        pre_pay: {title: '平台',type:'dict_key',dict_key:'pro_pay_from',width:80},
        pay_from: {title: '付款方式',type:'dict_key',dict_key:'pro_pay_from',width:80},
        buy_type: {title: '升级类型',type:'dict_key',dict_key:'pro_buy_type',width:80},
        auth_type: {title: '产品种类',width:120,type:'dict_key',dict_key:'pro_auth_type'},
        // product_title: {title: '产品种类',width:120},
        price: {title: '原价',width:60},
        net_sum: {title: '节点',width:60},
        invoice_id: {title: '发票',width:60,type:'slot',slot:'invoice'},
        pay_price: {title: '付款金额',width:90,type:'pay_price'},
        pay_status: {title: '付款状态',width:90,dict_key:'pro_pay_status',type:'dict_tag'},
        refund_status: {title: '退款状态',width:95,dict_key:'pro_refund_status',type:'dict_tag'},
        refund_price: {title: '退款金额'},
        pay_time: {title: '付款时间',type:'daterange',valueFormat:'timestamp'},
        create_time: {title: '创建时间',type:'daterange',valueFormat:'timestamp'},
    },
    rules: {
        username: [
            {required: true, message: '请指定用户名', trigger: 'blur'},
        ]
    },
    group: {
        show:{
            fields:['pay_from','auth_type','net_sum','pay_status','create_time','pay_price','pay_time','invoice_id'],
        },
        export:{
            fields:['transaction_id','pay_from','pay_price','pay_status','refund_status','refund_price','create_time','pay_price','pay_time'],
        },
        search: {
            fields: ['pay_status'],
        },
    }
}
