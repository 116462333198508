<template>
    <div>

    </div>
</template>
<script>

    export default {
        data(){
            return {
                baseUrl:'/bbs/',
            }
        },
        mounted() {
            // let query=this.$route.query;
            // this.$axios.post('',{
            //     token:query.token,
            // }).then(data=>{
            //     //处理登录的业务
            //     if (query.jump_url){
            //
            //     }else{
            //         this.$route.push({
            //             path:'/index',
            //         });
            //     }
            // });
        }
    }
</script>
<style scoped lang="less">

</style>